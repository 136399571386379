import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { compose } from 'redux';
import { filters } from '../../../config/marketplace-custom-config';

import { NamedLink } from '../../../components';
import { AnimatedListItem } from '../AnimatedListItem';
import { HorizontalList } from '../HorizontalList';

function CategoryCard(props) {
  const { name, categoryKey, icon, history } = props;

  return (
    <NamedLink
      name="SearchPage"
      params={{ type: 'bike', category: categoryKey }}
      state={{ prevPath: history.location.pathname }}
    >
      <AnimatedListItem>
        <Stack gap={2} p={2} alignItems="center">
          <Box
            component="img"
            src={icon}
            height={{ xs: 73, md: 160 }}
            width={{ xs: 140, md: '100%' }}
            sx={{ objectFit: 'contain', objectPosition: 'center' }}
          />
          <Typography fontSize={{ xs: 16, md: 18 }} fontWeight={400} textAlign="center">
            {name}
          </Typography>
        </Stack>
      </AnimatedListItem>
    </NamedLink>
  );
}

function SectionCategories(props) {
  const { intl, history } = props;

  const categories = filters.find(filter => filter.id === 'category').config.options;
  const categoriesForDisplay = ['mountain', 'road', 'gravel', 'electric'];

  return (
    <HorizontalList>
      {categoriesForDisplay
        .map(key => categories.find(category => category.key === key))
        .map(category => (
          <CategoryCard
            name={intl.formatMessage({ id: `categories.${category.key}` })}
            key={category.key}
            categoryKey={category.key}
            icon={category.bigLogo ?? category.logo}
            history={history}
          />
        ))}
    </HorizontalList>
  );
}

export default compose(withRouter)(SectionCategories);
